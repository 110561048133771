import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        conversationId: Number,
    }

    connect() {
        console.log(this.element.dataset.conversationControllerConversationIdValue);
        console.log(this.conversationIdValue);
        document.addEventListener("turbo:before-stream-render", (event) => {

            if (event.target.action === "read") {
              const messageElement = document.getElementById(event.target.target);
              
              if (messageElement) {
                this.resetUnreadCount();
              }
          
              // Prevent the default Turbo behavior (like replacing or appending content)
              event.preventDefault();
            }
          });
      }

    resetUnreadCount() {
        fetch(`/conversations/${this.conversationIdValue}/clean`, {
          method: 'POST',
          headers: {
            'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
            'Content-Type': 'application/json',
          },
          credentials: 'same-origin'
        });
    }
}